import React, { useRef } from "react";

import useComposedRefs from "hooks/useComposedRefs";

import Box from "components/Box";

const defaultProps = {
  rows: 6,
};

const baseStyles = {
  display: "block",
  width: "100%",
  py: 2,
  px: 3,
  border: "1px solid",
  borderColor: "gray.200",
  borderRadius: "sm",
  bg: "white",
  color: "inherit",
  fontSize: "inherit",
  fontFamily: "inherit",
  lineHeight: "inherit",
  appearance: "none",
  "::placeholder": {
    color: "gray.200",
    opacity: 1,
  },
  ":hover:not(:focus)": {
    bg: "gray.100",
  },
  "[data-invalid] &": {
    borderColor: "brick.900",
  },
};

const Textarea = React.forwardRef(({ onChange, ...props }, forwardedRef) => {
  const ownRef = useRef();
  const ref = useComposedRefs(forwardedRef, ownRef);

  function handleChange(event) {
    if (onChange) {
      onChange(event.target.value);
    }
  }

  return (
    <Box
      __css={baseStyles}
      __themeKey="forms"
      as="textarea"
      onChange={handleChange}
      ref={ref}
      variant="textarea"
      {...props}
    />
  );
});

Textarea.defaultProps = defaultProps;

export default Textarea;
